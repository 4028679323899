<template>
  <VueDraggable
    v-model="localTasks"
    v-bind="$attrs"
    group="tasks"
    item-key="id"
    :sort="false"
    @end="onDragEnd"
  >
    <template #item="{element}">
      <PlannerTask :task="element" :compact="compact" />
    </template>
  </VueDraggable>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import VueDraggable from "vuedraggable";

import { db, createTimestamp } from "@/firebase";
import PlannerTask from "./PlannerTask.vue";

export default {
  name: "PlannerDraggableTasks",
  components: {
    VueDraggable,
    PlannerTask
  },
  props: {
    compact: {
      type: Boolean,
      default: false
    },
    tasks: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const localTasks = ref([...props.tasks]);
    watch(
      () => props.tasks,
      () => (localTasks.value = props.tasks)
    );

    const onDragEnd = async event => {
      emit("end");

      const { taskId } = event.item.dataset;
      if (!taskId) return;

      if (!event.to.dataset.backlogDropZone && !event.to.dataset.date) return;

      const newPlannedAt = event.to.dataset.backlogDropZone
        ? null
        : createTimestamp(new Date(event.to.dataset.date));

      await db
        .collection(`companies/${route.params.companyId}/tasks`)
        .doc(taskId)
        .update({ plannedAt: newPlannedAt });
    };

    return {
      localTasks,
      onDragEnd
    };
  }
};
</script>

<style lang="scss" scoped>
.planner-week-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__header {
    align-items: center;
    border-bottom: 1px solid $base-color;
    display: flex;
    justify-content: space-between;
    margin: 16px 32px 32px;
    padding-bottom: 16px;

    & button {
      align-items: center;
      background-color: $base-darkbeige;
      border-radius: 3px;
      color: $base-color;
      display: flex;
      font-weight: 500;
      font-size: 18px;
      height: 30px;
      justify-content: center;
      margin-left: 4px;
      padding: 6px 12px;

      &:first-child {
        margin-left: 0;
      }
    }

    .weekNav {
      display: flex;
    }
  }

  &__container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(7, minmax(240px, 3fr));
    overflow: auto;
  }
}

.backlog-drop-zone {
  height: 200px;
  width: 200px;
  border: 2px dotted blue;
}
</style>
