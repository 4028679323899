<template>
  <div v-if="shouldShowAlert" class="mood-alert">
    <div class="mood-alert__emoji" @click="showTooltip = !showTooltip">
      <MoodItem :value="dominantNegativeMood" />
    </div>
    <div v-if="showTooltip" class="mood-alert__message">
      <h5>Mood Insider note</h5>
      <h6>
        Based on your overall mood from last week, we recommend taking less on
        this week. Adjust your workload accordingly
      </h6>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import dayjs from "dayjs";

import useMoodStatisticsForRange from "@/composables/useMoodStatisticsForRange";
import MoodItem from "@/components/mood/MoodItem.vue";

export default {
  name: "PlannerMoodStatisticsAlert",
  components: {
    MoodItem
  },
  setup() {
    const today = ref(dayjs());
    const twoWeeksAgo = computed(() => today.value.subtract(2, "weeks"));
    const { statistics, daysCount, entriesCount } = useMoodStatisticsForRange(
      twoWeeksAgo,
      today
    );

    const hasMoreThanHalfEntries = computed(
      () => entriesCount.value > daysCount.value / 2
    );

    const negativeMoodsCount = computed(
      () => statistics.value["worried"] + statistics.value["stressed"]
    );

    const hasMajorityOfNegativeMoods = computed(
      () => negativeMoodsCount.value >= entriesCount.value / 2
    );

    const dominantNegativeMood = computed(() =>
      statistics.value["stressed"] >= statistics.value["worried"]
        ? "stressed"
        : "worried"
    );

    const shouldShowAlert = computed(
      () => hasMoreThanHalfEntries.value && hasMajorityOfNegativeMoods.value
    );

    const showTooltip = ref(true);

    return {
      daysCount,
      entriesCount,
      dominantNegativeMood,
      hasMoreThanHalfEntries,
      negativeMoodsCount,
      hasMajorityOfNegativeMoods,
      shouldShowAlert,
      showTooltip
    };
  }
};
</script>

<style lang="scss" scoped>
.mood-alert {
  position: relative;
  margin-bottom: 12px;

  &__emoji {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__message {
    position: absolute;
    text-align: left;
    top: 0;
    left: 95%;
    transform: translateY(-50%);
    background: white;
    border-radius: 9px;
    box-shadow: 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 1px rgb(0 0 0 / 10%),
      0px 2px 12px rgb(0 0 0 / 15%);
    color: #2a1f60;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 8px;
    min-width: 220px;
  }

  h5 {
    margin: 0 0 16px 0;
  }

  h6 {
    line-height: 22px;
    margin: 0;
  }
}
</style>
