<template>
  <div class="planner-week-view">
    <div class="planner-week-view__header">
      <div class="planner-week-view__header-title">
        <h4>{{ formattedDate }}</h4>
        <span class="planner-week-view__header-separator"></span>
        <span class="planner-week-view__header-week"
          >Week {{ weekNumber }}</span
        >
      </div>
      <div class="planner-week-view__header-nav">
        <div class="planner-backlog__sort">
          Sort by:
          <div class="planner-backlog__sortFilters">
            <select v-model="sortCriteria" class="longer">
              <option value="estimatedTime">Est. time</option>
              <option value="priority">Priority</option>
            </select>
            <select v-model="sortDirection">
              <option value="asc">Asc</option>
              <option value="desc">Desc</option>
            </select>
          </div>
        </div>
        <button @click="goToPreviousWeek" class="clickable">
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.00548193 6.00191C-0.0304182 5.56629 0.109278 5.11753 0.424568 4.78414L4.52478 0.448513C5.0909 -0.150111 6.00876 -0.15011 6.57488 0.448515C7.141 1.04714 7.141 2.0177 6.57488 2.61633L3.37561 5.99929L6.57528 9.38266C7.1414 9.98129 7.1414 10.9518 6.57527 11.5505C6.00915 12.1491 5.09129 12.1491 4.52517 11.5505L0.424958 7.21485C0.110833 6.88269 -0.0289925 6.43602 0.00548193 6.00191Z"
              fill="#2A1F60"
            />
          </svg>
        </button>
        <button @click="goToCurrentWeek" class="clickable">Today</button>
        <button @click="goToNextWeek" class="clickable">
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.99455 5.99745C7.03017 6.43279 6.89045 6.88114 6.57537 7.2143L2.47498 11.55C1.90884 12.1487 0.990932 12.1487 0.424786 11.55C-0.14136 10.9514 -0.141359 9.9808 0.424787 9.38216L3.62418 5.99915L0.424533 2.61587C-0.141612 2.01723 -0.141612 1.04665 0.424534 0.448011C0.990679 -0.150627 1.90858 -0.150627 2.47473 0.448011L6.57512 4.78373C6.88944 5.11609 7.02925 5.5631 6.99455 5.99745Z"
              fill="#2A1F60"
            />
          </svg>
        </button>
      </div>
    </div>

    <PlannerMoodMessageModal />

    <div class="planner-week-view__container">
      <div class="planner-week-view__max-hours">
        <div class="planner-week-view__max-hours-spacer"></div>

        <div
          class="planner-week-view__max-hours-cursor"
          :style="{ top: maxHoursOffsetTop }"
        >
          <PlannerMoodStatisticsAlert />
          <span>{{ maxHoursPerDay }} HRS</span>
        </div>
      </div>

      <PlannerWeekViewDay
        v-for="weekDay in weekDays"
        :key="weekDay.date.unix()"
        :date="weekDay.date"
        :tasks="weekDay.tasks"
        :max-hours="maxHoursPerDay"
        :sort-criteria="sortCriteria"
        :sort-direction="sortDirection"
        @drag-start="showBacklogDropZone = true"
        @drag-end="showBacklogDropZone = false"
      />
    </div>

    <SlideUpTransition>
      <PlannerDraggableTasks
        v-if="showBacklogDropZone"
        class="backlog-drop-zone"
        data-backlog-drop-zone="true"
        ghost-class="planner-task--compact-ghost"
        compact
      />
    </SlideUpTransition>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import dayjs from "dayjs";

import getUser from "@/composables/getUser";
import useWeekDays from "@/composables/useWeekDays";
import useKeydown from "@/composables/useKeydown";
import SlideUpTransition from "@/components/transitions/SlideUpTransition.vue";
import PlannerMoodMessageModal from "./PlannerMoodMessageModal.vue";
import PlannerMoodStatisticsAlert from "./PlannerMoodStatisticsAlert.vue";
import PlannerWeekViewDay from "./PlannerWeekViewDay.vue";
import PlannerDraggableTasks from "./PlannerDraggableTasks.vue";

export default {
  name: "PlannerWeekView",
  components: {
    SlideUpTransition,
    PlannerMoodMessageModal,
    PlannerMoodStatisticsAlert,
    PlannerDraggableTasks,
    PlannerWeekViewDay
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { userDetails } = getUser();
    const currentDate = ref(dayjs().startOf("day"));
    const formattedDate = computed(() => currentDate.value.format("MMMM YYYY"));
    const weekNumber = computed(() => currentDate.value.week());

    const goToCurrentWeek = () => (currentDate.value = dayjs().startOf("day"));
    const goToNextWeek = () =>
      (currentDate.value = currentDate.value.add(1, "week"));
    const goToPreviousWeek = () =>
      (currentDate.value = currentDate.value.subtract(1, "week"));

    const getTasksForDay = date => {
      return props.tasks.filter(task => {
        if (!task.plannedAt) return false;

        return dayjs(task.plannedAt.toDate()).isSame(date, "day");
      });
    };

    const weekDays = computed(() => {
      const daysThisWeek = useWeekDays(currentDate);
      return daysThisWeek.value.map(date => ({
        date,
        tasks: getTasksForDay(date)
      }));
    });

    const showBacklogDropZone = ref(false);
    const maxHoursPerDay = computed(
      () => userDetails.value?.workingHoursPerDay
    );

    const maxHoursOffsetTop = computed(
      () => `${(maxHoursPerDay.value + 1) * 64 + 5}px`
    );

    const sortCriteria = ref("estimatedTime");
    const sortDirection = ref("desc");

    useKeydown([
      {
        key: "t",
        fn: goToCurrentWeek
      },
      {
        key: "ArrowLeft",
        fn: goToPreviousWeek
      },
      {
        key: "ArrowRight",
        fn: goToNextWeek
      }
    ]);

    return {
      formattedDate,
      weekNumber,
      sortCriteria,
      sortDirection,
      weekDays,
      goToCurrentWeek,
      goToNextWeek,
      goToPreviousWeek,
      showBacklogDropZone,
      maxHoursPerDay,
      maxHoursOffsetTop
    };
  }
};
</script>

<style lang="scss" scoped>
.planner-week-view {
  font-family: GreycliffCF-Bold !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__header {
    align-items: center;
    border-bottom: 1px solid $base-color;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 24px;
    padding-bottom: 16px;
    width: 1000px;

    &-title {
      display: flex;
      align-items: baseline;
      color: $base-color;
    }

    &-separator {
      height: 16px;
      width: 1px;
      background-color: $base-color;
      margin: 0 16px;
    }

    &-week {
      font-family: GreycliffCF-Bold;
      font-size: $t14;
    }

    & button {
      align-items: center;
      background-color: $base-darkbeige;
      border: none;
      border-radius: 3px;
      color: $base-color;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      height: 30px;
      justify-content: center;
      margin-left: 4px;
      padding: 6px 12px;

      &:hover {
        background: #ffb77d;
        color: $base-color;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &-nav {
      display: flex;
      align-items: center;
    }
  }

  .planner-backlog {
    &__sort {
      align-items: center;
      color: $base-color;
      display: flex;
      font-size: 18px;
    }

    &__sortFilters {
      display: flex;
      margin: 0 8px 0 4px;
    }

    &__sortFilters select {
      background-color: transparent;
      background-position: 90% center;
      border: 2px solid;
      box-shadow: none;
      font-family: GreycliffCF-Bold !important;
      font-size: 13px;
      font-weight: 500;
      line-height: 26px;
      height: auto;
      margin: 0 0 0 4px;
      text-transform: uppercase;
      width: 90px;
    }
    &__sortFilters select.longer {
      width: 118px;
    }
  }

  &__container {
    position: relative;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 72px repeat(7, minmax(240px, 3fr));
    overflow: auto;
  }

  &__max-hours {
    position: relative;
    z-index: 2;

    &-spacer {
      position: sticky;
      top: 0;
      z-index: 2;
      box-sizing: border-box;
      height: 64px;
      border-bottom: 1px solid rgba($base-color, 0.35);
    }

    &-cursor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $base-color;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba($base-color, 0.35);
      transform: translateY(-100%);
      font-family: GreycliffCF-Bold;
      font-size: $t14;
      text-align: right;
    }
  }
}

.backlog-drop-zone {
  box-shadow: $shadow-xl;
  position: fixed;
  bottom: 32px;
  left: 50%;
  height: 48px + 16px;
  width: 268px + 16px;
  padding-top: 8px;
  border: 1px dashed $base-color;
  border-radius: 9px;
  background-color: rgba(white, 0.95);
  z-index: 10;

  &::before {
    content: "Place back in backlog";
    font-family: GreycliffCF-Bold;
    color: $base-color;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
  }
}
</style>
