import { computed } from "vue";

export default function useWeekDays(initialDate) {
  const weekdays = computed(() =>
    Array.from(Array(7).keys()).map(weekDay =>
      initialDate.value.weekday(weekDay).startOf("day")
    )
  );

  return weekdays;
}
