<template>
  <div class="container">
    <div class="wrapper">
      <!-- <div class="titlesBlock">
        <div class="greetings">Start planning your week</div>
        <h2>My Personal Planner</h2>
      </div> -->
      <BaseButton
        modifiers="primary"
        class="cool"
        aria-label="toggle-backlog"
        @click="toggleBacklog"
      >
        Open my backlog
      </BaseButton>
    </div>

    <PlannerWeekView :tasks="tasks" />

    <transition name="slide-fade">
      <PlannerBacklog
        v-if="isBacklogOpen"
        :tasks="tasks"
        @close="closeBacklog"
      />
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { db } from "@/firebase";
import getUser from "@/composables/getUser";
import useKeydown from "@/composables/useKeydown";

import PlannerWeekView from "@/views/planner/PlannerWeekView.vue";
import PlannerBacklog from "@/views/planner/PlannerBacklog.vue";
import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: {
    PlannerWeekView,
    PlannerBacklog,
    BaseButton
  },
  setup() {
    const route = useRoute();
    const isBacklogOpen = ref(false);
    const toggleBacklog = () => (isBacklogOpen.value = !isBacklogOpen.value);
    const closeBacklog = () => (isBacklogOpen.value = false);

    const { user } = getUser();

    const tasks = ref([]);
    db.collection(`companies/${route.params.companyId}/tasks`)
      .where("userId", "==", user.value.uid)
      .onSnapshot(snap => {
        tasks.value = snap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      });

    useKeydown([
      {
        key: "b",
        fn: toggleBacklog
      }
    ]);

    return {
      isBacklogOpen,
      toggleBacklog,
      closeBacklog,
      tasks
    };
  }
};
</script>

<style lang="scss" scoped>
.cool {
  border: 2px solid $base-color;
  color: $base-color;
  background: none;
  font-weight: 500;
  margin-top: 32px;
  padding: 6px 16px;
  width: fit-content;
  &:hover {
    background: $base-color;
    color: white;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(0.5, 0, 0.5, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(32px);
  opacity: 0;
}

#grid-snap-container {
  height: 1536px;
  width: 268px;
  background-color: #e7e7e7;
}

#grid-snap {
  width: 268px;
  height: 64px;
  border-radius: 4px;
  background-color: #29e;
  color: #fff;
  font-size: 1.2em;
  touch-action: none;
}
</style>
