<template>
  <div
    class="planner-week-view-day"
    :class="{
      'planner-week-view-day--today': date.isToday(),
      'planner-week-view-day--weekend': isWeekend
    }"
  >
    <div v-if="openNav">
      <div class="miniNav clickable">
        <div @click="toggleNav" v-click-away="onClickAway">
          You exceeded your time limit for the day. Move tasks around to clear
          some space (and your mind)!
        </div>
      </div>
    </div>
    <div class="planner-week-view-day__row planner-week-view-day__row-title">
      {{ date.format("ddd DD") }}

      <span
        v-if="exceedsMaxWorkingHours"
        style="margin-left:8px"
        class="clickable"
      >
        <div>
          <svg
            @click="toggleNav"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10.1 5.46362C9.49086 5.46362 8.99995 5.95453 8.99995 6.56362L8.99995 10.5727C8.99995 11.1818 9.49086 11.6727 10.1 11.6727C10.709 11.6727 11.2 11.1818 11.2 10.5727L11.2 6.56362C11.2 5.95453 10.709 5.46362 10.1 5.46362ZM9.00904 13.5999C9.00904 13.0181 9.49995 12.5363 10.109 12.5363C10.709 12.5363 11.2 13.0181 11.2 13.5999C11.2 14.1727 10.709 14.6363 10.109 14.6363C9.49995 14.6363 9.00904 14.1727 9.00904 13.5999Z"
              fill="#FF7D49"
            />
          </svg>
        </div>
      </span>
    </div>

    <PlannerDraggableTasks
      class="planner-week-view-day__rows"
      :data-date="date.toString()"
      :tasks="sortedTasks"
      @start="$emit('drag-start')"
      @end="$emit('drag-end')"
    />

    <div
      v-if="maxHours"
      class="planner-week-view-day__max-hours"
      :style="{ top: maxHoursOffsetTop }"
    ></div>
  </div>
</template>

<script>
import { ref, computed, toRef } from "vue";

import useSortedTasks from "@/composables/useSortedTasks";
import PlannerDraggableTasks from "./PlannerDraggableTasks.vue";

export default {
  name: "PlannerWeekViewDay",
  components: {
    PlannerDraggableTasks
  },
  props: {
    date: {
      type: Object,
      required: true
    },
    sortCriteria: {
      type: String,
      default: "estimatedTime"
    },
    sortDirection: {
      type: String,
      default: "desc"
    },
    tasks: {
      type: Array,
      default: () => []
    },
    maxHours: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const totalHoursForTheDay = computed(() =>
      props.tasks
        .map(({ estimatedTime }) => parseInt(estimatedTime, 10))
        .reduce((total, estimatedTime) => total + estimatedTime, 0)
    );
    const exceedsMaxWorkingHours = computed(
      () => totalHoursForTheDay.value > props.maxHours
    );
    const maxHoursOffsetTop = computed(
      () => `${(props.maxHours + 1) * 64 + 5}px`
    );

    const isWeekend = computed(() => [0, 6].includes(props.date.get("day")));

    const { sortedTasks } = useSortedTasks({
      tasks: toRef(props, "tasks"),
      criteria: toRef(props, "sortCriteria"),
      direction: toRef(props, "sortDirection")
    });

    const openNav = ref(false);

    const onClickAway = () => {
      openNav.value = false;
    };

    return {
      sortedTasks,
      totalHoursForTheDay,
      exceedsMaxWorkingHours,
      maxHoursOffsetTop,
      isWeekend,
      onClickAway,
      openNav
    };
  },
  methods: {
    toggleNav() {
      this.openNav = !this.openNav;
    }
  }
};
</script>

<style lang="scss" scoped>
.miniNav {
  background: #ffb77d;
  border-radius: 9px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.15),
    0px 0px 6px rgba(0, 0, 0, 0.15);
  color: $base-color;
  display: block ruby;
  font-size: 16px;
  left: 0;
  line-height: 22px;
  margin: 0 8px;
  padding: 24px;
  position: absolute;
  text-transform: none;
  top: 48px;
  width: auto;
  z-index: 1000;
}
.planner-week-view-day {
  position: relative;
  display: flex;
  flex-direction: column;

  &--weekend {
    background-color: $base-darkbeige;
  }

  &--today {
    background: $base-aurora;
  }

  &__max-hours {
    position: absolute;
    left: 0;
    top: 120px;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid rgba($base-color, 0.35);
    transform: translateY(-100%);
  }

  &__row-title {
    position: sticky;
    top: 0;
    height: 64px;
    padding: 8px;
    box-sizing: border-box;
    color: $base-color;
    background-color: $base-lightbeige;
    border-bottom: 1px solid rgba($base-color, 0.35);
    font-family: GreycliffCF-Bold !important;
    font-size: 14px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    z-index: 2;
  }

  &__rows {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-left: 1px solid $base-color;
    padding-top: 8px;
  }

  &__task {
    min-height: 64px;
    margin: 8px;
  }
}
</style>
