<template>
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.733487 1.15496C1.06212 0.800657 1.61575 0.779851 1.97005 1.10849L6.92015 5.70001C7.69037 6.40519 7.69037 7.59481 6.92015 8.3L1.97005 12.8915C1.61575 13.2202 1.06212 13.1994 0.733487 12.8451C0.404851 12.4908 0.425657 11.9371 0.779957 11.6085L5.73818 7.00943C5.74277 7.00525 5.74526 7.00218 5.74645 7.00053C5.74658 7.00035 5.74634 7.0007 5.74645 7.00053C5.74634 7.00036 5.74658 6.99966 5.74645 6.99948C5.74526 6.99783 5.74279 6.99474 5.7382 6.99055L5.73243 6.9853L0.779957 2.39152C0.425657 2.06289 0.404851 1.50926 0.733487 1.15496Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "IconChevronRight"
};
</script>
