<template>
  <div class="planner-backlog" v-show="!isDragging">
    <div class="planner-backlog__backdrop" @click="$emit('close')"></div>
    <div class="planner-backlog__container">
      <div class="planner-backlog__header">
        <h4>My backlog</h4>
      </div>
      <div class="planner-backlog__close" @click="$emit('close')">
        <IconChevronRight />
      </div>
      <div class="planner-backlog__sort">
        Sort by:
        <div class="planner-backlog__sortFilters">
          <select v-model="sortCriteria">
            <option value="estimatedTime">Est. time</option>
            <option value="priority">Priority</option>
          </select>
          <select v-model="sortDirection">
            <option value="asc">Asc</option>
            <option value="desc">Desc</option>
          </select>
        </div>
      </div>
      <div class="planner-backlog__items">
        <PlannerDraggableTasks
          :tasks="tasksNotPlannedOrdered"
          :compact="true"
          @start="isDragging = true"
          @end="isDragging = false"
        />
      </div>

      <div
        v-if="tasksNotPlannedOrdered.length === 0"
        class="planner-backlog__no-items"
      >
        Looks like you have nothing left to plan, great job!
      </div>
      <div v-else className="user-note">
        <div className="user-note__arrow">
          <svg
            width="37"
            height="20"
            viewBox="0 0 37 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.75101 8.45966C5.05419 10.3472 4.99273 12.2388 4.60294 14.1173C4.47044 14.7556 4.01379 15.2666 3.30761 15.2031C2.7187 15.153 2.09164 14.5397 2.22186 13.9078C2.62421 11.9695 2.66717 10.1318 2.26975 8.20048C2.10029 7.37158 1.89625 6.55141 1.69222 5.73127C1.41347 4.6108 1.13474 3.49038 0.944188 2.34778C0.857983 1.82838 1.13578 1.28541 1.61138 1.06135C2.1302 0.816924 2.62607 1.00597 3.03476 1.3576C5.58489 3.66422 8.56137 5.38431 12.1108 5.0012C13.6327 4.83367 14.0501 7.18879 12.5176 7.35607C10.3746 7.58999 8.22828 7.32474 6.25865 6.4282C6.98965 7.5519 7.82814 8.60564 8.76472 9.58375C15.1948 16.3019 25.914 19.071 34.573 15.3139C35.9855 14.7012 37.004 16.863 35.5914 17.4757C32.8808 18.6523 29.9994 19.2649 27.0487 19.3712C18.4868 19.6821 9.70893 15.4658 4.75101 8.45966Z"
              fill="#2a1f60"
            />
          </svg>
        </div>
        <div className="user-note__text">
          Drag any item and drop it into your planner to organize your work as
          you see fit!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import useKeydown from "@/composables/useKeydown";
import useSortedTasks from "@/composables/useSortedTasks";

import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import PlannerDraggableTasks from "./PlannerDraggableTasks.vue";

export default {
  name: "PlannerBacklog",
  components: {
    IconChevronRight,
    PlannerDraggableTasks
  },
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const close = () => emit("close");
    useKeydown([
      {
        key: "Escape",
        fn: close
      }
    ]);

    const isDragging = ref(false);

    const tasksNotPlanned = computed(() =>
      props.tasks.filter(task => !task.plannedAt)
    );

    const sortCriteria = ref("estimatedTime");
    const sortDirection = ref("desc");
    const { sortedTasks: tasksNotPlannedOrdered } = useSortedTasks({
      tasks: tasksNotPlanned,
      criteria: sortCriteria,
      direction: sortDirection
    });

    return {
      close,
      sortCriteria,
      sortDirection,
      tasksNotPlannedOrdered,
      isDragging
    };
  }
};
</script>

<style lang="scss" scoped>
.planner-backlog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(42, 31, 96, 0.35);
  }

  &__container {
    position: relative;
    background-color: #fff9f5;
    font-family: GreycliffCF-Bold !important;
    font-weight: bold;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 314px;
    z-index: 2;
  }

  &__close {
    align-items: center;
    background: linear-gradient(113.61deg, #ff5c00 0%, #ffa800 100%);
    border-radius: 3px 0 0 3px;
    display: flex;
    height: 32px;
    justify-content: center;
    left: -24px;
    position: absolute;
    top: 12px;
    width: 24px;
    z-index: 3;
  }

  &__header {
    height: 56px;
    background: white;
    box-shadow: 1px 1px 0px rgb(39 35 67 / 10%), 0px 0px 1px rgb(39 35 67 / 15%),
      0px 0px 6px rgb(39 35 67 / 15%);
    font-size: 21px;
    line-height: 52px;
    padding: 0 12px;
    width: 100%;
  }

  &__sort {
    color: $base-color;
    font-size: 18px;
    padding: 24px 12px;
  }

  &__sortFilters {
    display: flex;
    margin: 12px 0;
    width: 100%;
  }

  select {
    background-color: transparent;
    background-position: 90% center;
    border: 2px solid;
    box-shadow: none;
    font-family: GreycliffCF-Bold !important;
    font-size: 13px;
    line-height: 26px;
    height: auto;
    margin: 0 4px;
    text-transform: uppercase;
    width: 50%;
  }

  &__items {
    padding: 0 12px 24px;
  }

  &__no-items {
    padding: 24px;
    margin: 0 12px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    border: 1px dashed $base-color;
    border-radius: 6px;
    color: $base-color;
  }
}

.user-note {
  display: flex;
  left: -24px;
  margin-top: 24px;
  position: absolute;

  &__arrow {
    height: 36px;
    margin-top: 40px;
    width: 40px;
  }

  &__text {
    color: $base-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    margin-left: 12px;
    transform: rotate(-2deg);
    width: 200px;
  }
}
</style>
