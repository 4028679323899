<template>
  <div
    class="planner-task clickable"
    :class="{ 'planner-task--compact': compact }"
    :data-task-id="task.id"
    :style="computedStyles"
  >
    <router-link
      tag="div"
      class="planner-task__container"
      :to="{ name: 'TaskDetails', params: { taskId: task.id } }"
    >
      <div class="planner-task__work-bar" />
      <div class="planner-task__title">
        <PriorityFlag
          class="planner-task__priority-flag"
          :priority="task.priority"
        />
        {{ task.name }}
      </div>
      <div class="planner-task__duration">{{ task.estimatedTime }} HRS</div>
    </router-link>
  </div>
</template>

<script>
import { computed } from "vue";

import PriorityFlag from "@/components/commons/PriorityFlag.vue";

export default {
  name: "PlannerTask",
  components: {
    PriorityFlag
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const computedStyles = computed(() => {
      const styles = {};

      if (!props.compact) {
        styles.height = `${props.task.estimatedTime * 64}px`;
      }

      return styles;
    });

    return { computedStyles };
  }
};
</script>

<style lang="scss" scoped>
.planner-task {
  min-height: 64px;
  padding: 0 8px 8px;
  display: flex;
  box-sizing: border-box;

  &--compact,
  &.planner-task--compact-ghost {
    height: auto !important;

    .planner-task__container {
      align-items: center;
    }
    .planner-task__duration {
      margin-top: 0;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 3px;
    background: white;
    box-shadow: $shadow-sm;
    padding: 16px 12px;
    transition: all 0.35s;
    flex-grow: 1;
    &:hover {
      box-shadow: $shadow-l;
    }
  }

  &__priority-flag {
    margin-right: 6px;
  }

  &__work-bar {
    position: absolute;
    border-radius: 3px 0px 0px 0px;
    height: 3px;
    top: 0px;
    left: 0px;
  }

  &__title {
    display: flex;
    align-items: center;
    color: #2a1f60;
    font-family: "GreycliffCF-Bold";
    font-size: 18px;
    line-height: 22px;
    padding: 0;
  }

  &__duration {
    color: #2a1f60;
    font-family: "GreycliffCF-Bold";
    font-size: 13px;
    margin-top: 6px;
    padding: 0;
    flex-shrink: 0;
  }
}
</style>
