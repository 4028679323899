<template>
  <Modal v-if="showModal" @close="closeModal">
    <div class="mood-message">
      <div class="mood-message__header">
        <MoodItem class="mood-message__header-mood-icon" :value="todayMood" />
        <h6 class="mood-message__header-title">Mood Insider note</h6>
      </div>
      <p class="mood-message__note">
        Based on your mood input from today, we recommend working a little less
        than usual
      </p>
      <button class="mood-message__dismiss" @click="closeModal">
        Dismiss
      </button>
    </div>
  </Modal>
</template>

<script>
import { computed } from "vue";
import useMoodForToday from "@/composables/useMoodForToday";
import useDailyFlag from "@/composables/useDailyFlag";

import Modal from "@/components/commons/Modal.vue";
import MoodItem from "@/components/mood/MoodItem.vue";

const PLANNER_MOOD_MESSAGE_STORAGE_KEY = "dawn-planner-mood-message";

export default {
  name: "PlannerMoodMessageModal",
  components: {
    Modal,
    MoodItem
  },
  setup() {
    const [todayMood] = useMoodForToday();
    const [shownToday, markAsShownToday] = useDailyFlag(
      PLANNER_MOOD_MESSAGE_STORAGE_KEY
    );

    const showModal = computed(
      () =>
        ["worried", "stressed"].includes(todayMood.value) && !shownToday.value
    );
    const closeModal = () => markAsShownToday();

    return {
      todayMood,
      showModal,
      closeModal
    };
  }
};
</script>

<style lang="scss" scoped>
.mood-message {
  background: white;
  border-radius: $b9;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  margin: 8px;
  max-width: 250px;
  margin: 0 auto;

  & h6 {
    line-height: 20px;
    margin: 0;
  }

  &__header {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    &-mood-icon {
      margin-right: 12px;
    }
  }

  &__note {
    margin: 16px 0 24px 0;
  }
}
</style>
